import React from 'react';
import { func } from 'prop-types';
import { Box, Text, Button, Center, Image } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { 
  ENABLE_GENERATOR_INVITATION,
  ENABLE_QR_INVITATION,
  ENABLE_ANNOUNCEMENT_MODE,
  ENABLE_CUSTOM_LINK 
} from '@/constants/features-flag';

import { BUTTON_PROPS, CUSTOM_MODE, STATE_DISPLAY } from '../types';

/**
 * Function to render Welcome Screen
 * @param {props} props
 * @returns {JSX.Element}
 */
function WelcomeScreen({ onSetDisplay, onSetCustomInvitation }) {
  return (
    <Box color="white" fontFamily="jost" textAlign="center" padding="42px 0">
      <Text textAlign="left" textTransform="uppercase" fontSize="xl" letterSpacing="1px">
        Generator Link
      </Text>
      <Text marginTop="16px" textAlign="left">
        The Wedding of
      </Text>
      <Text textAlign="left" fontFamily="cursive" fontSize="3xl">
        {THE_BRIDE}
      </Text>
      {/* Button Sections */}
      <Box marginTop="24px">
        {!ENABLE_QR_INVITATION && ENABLE_CUSTOM_LINK && (
          <Button colorScheme="orange" {...BUTTON_PROPS} onClick={() => onSetCustomInvitation(CUSTOM_MODE.invitation)}>
            Buat Link Invitation
          </Button>
        )}
        {ENABLE_ANNOUNCEMENT_MODE && (
          <Button colorScheme="orange" {...BUTTON_PROPS} onClick={() => onSetCustomInvitation(CUSTOM_MODE.announcement)}>
            Buat Link Announcement
          </Button>
        )}
        {ENABLE_GENERATOR_INVITATION && (
          <Button colorScheme="orange" {...BUTTON_PROPS} onClick={() => onSetDisplay(STATE_DISPLAY.list)}>
            Lihat Daftar Undangan
          </Button>
        )}
      </Box>
      {/* Invitato Logo */}
      <Center>
        <Image
          src="https://user-images.githubusercontent.com/10141928/137603877-5d2ddf10-39a7-4586-8ab1-d9cca7fe8898.png"
          maxHeight="30px"
          marginTop="24px"
        />
      </Center>
    </Box>
  );
}

WelcomeScreen.propTypes = {
  onSetCustomInvitation: func.isRequired,
  onSetDisplay: func.isRequired,
};

export default WelcomeScreen;