import React, { useState, useMemo } from 'react';
import { oneOf } from 'prop-types';
import {
  Box,
  Text,
  Button,
  Link,
  FormControl,
  Input,
  FormLabel,
  Select,
  InputGroup,
  InputLeftAddon,
  Divider,
  Center,
  useToast,
} from '@chakra-ui/react';
import { RiFileCopy2Line, RiWhatsappLine } from 'react-icons/ri';

import copyTextToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import getBroadcastText from '../getBroadcastText';

import { THE_BRIDE, HOSTNAME } from '@/constants';
import { ENABLE_SHIFT_TIME_RECEPTION, ENABLE_DIFFERENT_GUEST_RSVP } from '@/constants/features-flag';
import {
  CUSTOM_MODE,
  LIST_BROADCAST,
  INPUT_PROPS,
  SELECT_PROPS,
  FORM_LABEL_OPTIONS,
} from '../types';

const SHIFT_LIST = [
  {
    name: 'Shift 1 (11.00 - 12.00 WIB)',
    value: '&shift=1',
  },
  {
    name: 'Shift 2 (12.00 - 13.00 WIB)',
    value: '&shift=2',
  },
];

const LANG_LIST = [
  {
    name: 'Bahasa Indonesia',
    value: '&lang=id',
  },
  {
    name: 'English',
    value: '&lang=en',
  },
];

const PARTNER_LIST = [
  {
    name: '1 Tamu',
    value: '&partner=1',
  },
  {
    name: '2 Tamu',
    value: '&partner=2',
  },
  {
    name: '3 Tamu',
    value: '&partner=3',
  },
  {
    name: '4 Tamu',
    value: '&partner=4',
  },
  {
    name: '5 Tamu',
    value: '&partner=5',
  },
];

/**
 * function to render Custom Invitation Generator
 * @param {props} props
 * @returns {JSX.Element}
 */
function CustomScreen({ type }) {
  const [text, setText] = useState('');
  const [guestName, setGuestName] = useState('');
  const [guestNumber, setGuestNumber] = useState('');
  const [broadcastId, setBroadcastId] = useState('');
  const [shift, setShift] = useState('');
  const [lang, setLang] = useState('');
  const [partner, setPartner] = useState('');
  const [showResult, setShowResult] = useState(false);
  const toast = useToast();

  const isInvitation = CUSTOM_MODE.invitation === type;
  const link =
    HOSTNAME +
    '?' +
    `to=${encodeURIComponent(guestName)}` +
    `${isInvitation ? '&type=invitation' : ''}` +
    shift + partner + lang;

  /**
   * Function to get list of broadcast text option
   * @returns {Object[]}
   */
  const broadcastOptions = useMemo(() => LIST_BROADCAST.filter((x) => x.type.includes(type)), [
    type,
  ]);

  const generateTextBroadcast = () => {
    const finalText = broadcastId ? getBroadcastText({ id: broadcastId, link, guestName }) : '';

    setText(finalText);
    setShowResult(true);
  };

  const handleChangeBroadcastType = (e) => {
    setBroadcastId(e.target.value);
    setShowResult(false);
  };

  const handleCopyText = async (customText) => {
    const result = await copyTextToClipboard(customText);
    if (result) {
      toast({
        status: 'success',
        title: 'Berhasil',
        description: 'Teks berhasil disalin di clipboard.',
      });
    } else {
      toast({
        title: 'Oops!',
        description: 'Terjadi kesalahan. Silahkan muat ulang browser Anda.',
      });
    }
  };

  const handlShowErrorToaster = (text) => {
    toast({
      isClosable: true,
      duration: null,
      title: 'Oops!',
      status: 'error',
      description: text,
    });
  };

  const handleSentToWhatsApp = () => {
    if (!guestNumber) {
      handlShowErrorToaster(
        'Untuk menggunakan fitur kirim via WhatsApp, Anda perlu memasukan nomor handphone tamu yang sudah teregistrasi di WhatsApp.',
      );
      return;
    }

    if (!guestName) {
      handlShowErrorToaster('Nama tamu belum dimasukan!');
      return;
    }

    window.open(`https://wa.me/62${guestNumber}?text=${encodeURIComponent(text)}`, '_blank');
  };

  return (
    <Box color="white" fontFamily="jost" paddingTop="24px">
      <Box maxWidth="500px">
        <Text marginTop="16px">The Wedding of</Text>
        <Text fontFamily="cursive" fontSize="3xl">
          {THE_BRIDE}
        </Text>
        {/* Section for Name value */}
        <FormControl>
          <FormLabel {...FORM_LABEL_OPTIONS}>Nama Tamu</FormLabel>
          <Input
            {...INPUT_PROPS}
            placeholder="Nama tamu.."
            type="text"
            value={guestName}
            onChange={(e) => setGuestName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel {...FORM_LABEL_OPTIONS}>Nomor HP (Whatsapp)</FormLabel>
          <InputGroup size="sm">
            <InputLeftAddon {...INPUT_PROPS} bgColor="rgb(155 104 25 / 61%)">
              +62
            </InputLeftAddon>
            <Input
              {...INPUT_PROPS}
              placeholder="8123456789"
              type="number"
              value={guestNumber}
              onChange={(e) => setGuestNumber(e.target.value)}
            />
          </InputGroup>
        </FormControl>
        {/* Section for Shift - If enable shift mode and is invitation */}
        {ENABLE_SHIFT_TIME_RECEPTION && isInvitation && (
          <Box>
            <FormLabel {...FORM_LABEL_OPTIONS}>Shift Undangan</FormLabel>
            <Select {...SELECT_PROPS} value={shift} onChange={(e) => setShift(e.target.value)}>
              <option style={{ color: "black"}} value="">--- Pilih Shift ---</option>
              {SHIFT_LIST.map((item) => {
                return (
                  <option style={{ color: "black"}} key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          </Box>
        )}
        {/* Section for Shift - If enable shift mode and is invitation */}
        {isInvitation && (
          <Box>
            <FormLabel {...FORM_LABEL_OPTIONS}>Bahasa Undangan</FormLabel>
            <Select {...SELECT_PROPS} value={lang} onChange={(e) => setLang(e.target.value)}>
              <option style={{ color: "black"}} value="">--- Pilih Bahasa ---</option>
              {LANG_LIST.map((item) => {
                return (
                  <option style={{ color: "black"}} key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          </Box>
        )}
        {/* Section for Partner */}
        {ENABLE_DIFFERENT_GUEST_RSVP && isInvitation && (
          <Box>
            <FormLabel {...FORM_LABEL_OPTIONS}>Pilih Jumlah Tamu</FormLabel>
            <Select {...SELECT_PROPS} value={partner} onChange={(e) => setPartner(e.target.value)}>
              <option style={{ color: "black"}} value="">--- Pilih Jumlah ---</option>
              {PARTNER_LIST.map((item) => {
                return (
                  <option style={{ color: "black"}} key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          </Box>
        )}
        {/* Tipe text broadcast */}
        <Box>
          <FormLabel {...FORM_LABEL_OPTIONS}>Teks Broadcast</FormLabel>
          <Select {...SELECT_PROPS} value={broadcastId} onChange={handleChangeBroadcastType}>
            <option style={{ color: "black"}} value="">--- Pilih Text Broadcast ---</option>
            {broadcastOptions.map((item) => {
              return (
                <option style={{ color: "black"}} key={item.value} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </Select>
        </Box>

        {/* Box Section */}
        <Button
          bgColor="white"
          fontWeight="normal"
          marginTop="16px"
          color="#9B6819"
          size="sm"
          onClick={generateTextBroadcast}
        >
          Buat Link Undangan
        </Button>
      </Box>

      {/* Show content share */}
      {showResult && (
        <Box>
          <Box
            fontSize="sm"
            bgColor="rgb(155 104 25 / 61%)"
            padding="16px 24px"
            margin="24px 0"
            borderRadius="40px"
            overflowY="auto"
          >
            {text ? <pre>{text}</pre> : <Text fontStyle="italic">{link}</Text>}
          </Box>
          {/* Link Action */}
          <Box fontSize="sm">
            <Center>
              <Link href={link} target="_blank">
                Uji Coba Link
              </Link>
            </Center>
            <Center>
              <Divider borderBottom="2px solid white" maxWidth="200px" margin="8px 0" />
            </Center>
            <Center>
              <RiFileCopy2Line style={{ marginRight: '4px' }} />
              <Text cursor="pointer" onClick={() => handleCopyText(link)}>
                Copy Link
              </Text>
            </Center>
            <Center>
              <Divider borderBottom="2px solid white" maxWidth="200px" margin="8px 0" />
            </Center>
            <Center>
              <RiFileCopy2Line style={{ marginRight: '4px' }} />
              <Text cursor="pointer" onClick={() => handleCopyText(text)}>
                Copy Link + Teks Broadcast
              </Text>
            </Center>
            <Center>
              <Divider borderBottom="2px solid white" maxWidth="200px" margin="8px 0" />
            </Center>
            <Center>
              <RiWhatsappLine style={{ marginRight: '4px' }} />
              <Text cursor="pointer" onClick={handleSentToWhatsApp}>
                Kirim Link ke WhatsApp
              </Text>
            </Center>
            <Center>
              <Divider borderBottom="2px solid white" maxWidth="200px" margin="8px 0" />
            </Center>
          </Box>
        </Box>
      )}
    </Box>
  );
}

CustomScreen.propTypes = {
  type: oneOf(['invitation', 'announcement']),
};

export default CustomScreen;